import axios from 'axios'
import NProgress from 'nprogress' // Zorg dat je NProgress hebt geïnstalleerd
import { router } from '@inertiajs/vue3' // Zorg dat Inertia router hier beschikbaar is

// Start NProgress op elke Axios-request
axios.interceptors.request.use((config) => {
	NProgress.start()
	return config
}, (error) => {
	NProgress.done() // Stop bij fout
	return Promise.reject(error)
})

// Stop NProgress bij elke Axios-response
axios.interceptors.response.use((response) => {
	NProgress.done()
	return response
}, (error) => {
	NProgress.done() // Stop bij fout
	return Promise.reject(error)
})

// Optioneel: Link Axios aan Inertia router-events
router.on('start', () => NProgress.start())
router.on('finish', (event) => {
	if (event.detail.visit.completed) {
		NProgress.done()
	} else if (event.detail.visit.interrupted) {
		NProgress.set(0)
	} else if (event.detail.visit.cancelled) {
		NProgress.done()
		NProgress.remove()
	}
})

router.on('cancel', () => NProgress.done())

export default axios
